html * {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}

a {
  color: #398722;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}
#sidebarContent a {
  font-weight: 400;
  color: white;
  text-decoration: none;
}

h1 {
  color: #398722;
  line-height: 1.25;
}

.company-listting a {
  color: #000;
  font-weight: 400;
}
.company-listting a:hover {
  text-decoration: none;
}
